<template>
  <b-row>
    <b-col cols="10" offset="1">
      <b-button :to="{ name: 'home' }" class="mb-2" variant="success">
        Back to Bookings
      </b-button>
      <h1 class="mb-2 text-primary">
        GET ALL THE INFORMATION YOU NEED TO PREPARE FOR YOUR UPCOMING FISHING ADVENTURE!
      </h1>
      <p><strong>Directions to the boats. ***Note: get the correct directions to the correct boat! They are in different areas of town!***</strong></p>
      <h4 class="text-primary">
        DIRECTIONS TO ALL BOATS:
      </h4>
      <p>
        Drive to California Dreaming Restaurant located at 1 Ashley Point Drive, Charleston SC, 29407. Park in the parking lot for California Dreaming, but park at the very end of the parking lot as far away from the restaurant as possible while still being in the parking lot. Directly in front of you is a short pier that goes out to the docks. This is the ONLY pier that will get you to our boats. At the end of that pier turn left down the docks. We will be the 10th and 11th boat down on the right. Wahoo! and Git R Done.
      </p>
      
      <strong class="d-block mb-50">
        Because people regularly ask:
      </strong>
      <p>
        The industry standard gratuity for the crew is 20%. However, the crew will gladly accept whatever tip you feel their efforts deserved. Our captains and mates work hard to optimize the experience of their clients and they rely on their tips to make a living. However, no customer should ever pay any amount of gratuity they feel was not earned. The gratuity should be given to the captain. He will ensure proper distribution.
      </p>
      <strong class="d-block mb-50">
        What you should bring:
      </strong>
      <p>
        Camera, sunglasses, sunscreen, hat, food, drink (beer is fine but no liquor or glass bottles please), a cooler to take your catch home. You may cancel your trip and have the deposit refunded at any point up to two weeks prior to the trip, and your deposit will be returned in full if your trip is canceled by your captain due to inclement weather, safety or mechanical issues. You can change the date of your trip to any other open date at any point up to seven days prior. You are free to change your package at any time (from 4 hour fun in the sun to 12 hour offshore fishing for example) provided the vessel and package you are switching to is available. Once your party has arrived and the vessel has departed, The full amount of the charter is due. No refund or discount of any kind is given should you need to end the trip early, as this would result in lost opportunity for the vessel and crew.
      </p>
      <strong class="d-block mb-50">
        Included in your trip:
      </strong>
      <p>
        Ice, bait, all fishing equipment, all fishing licenses and the crew will clean your catch.
      </p>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BButton,
  },
}
</script>
